import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom';

const Product = () => {
    const baseUrl = "https://modularkitchens.co.nz";
    // Product listing
    const [productList, setProductList] = useState([]);

    const getProducts = async () => {
        try {
            const response = await fetch(`${baseUrl}/products`, {
                method: 'GET',
            });
            const data = await response.json();
            setProductList(data.productList);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getProducts();
    }, []);

    const DeleteProduct = async (pid) => {
        try {
            const response = await fetch(`${baseUrl}/products/${pid}`, {
                method: 'DELETE',
            });
            const result = await response.json();
            if (result.success) {
                getProducts();
            } else {
                alert(result.message);
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <div className="container-fluid">
                <Sidebar />
                <div className="right-section">
                    <div className="dashboard-bar">
                        <h2 className="headn-h2">Products</h2>
                    </div>
                    <div className="main-section">
                        <div className="col-sm-12 col-md-12 col-xs-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h3 className="panel-title">Product list</h3>
                                </div>
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-sm-4" />
                                        <div className="col-sm-4">
                                            <Link to="/product/add" className="btn btn-primary center-block" type="button">Add New</Link>
                                        </div>
                                        <div className="col-sm-4" />
                                    </div>
                                    <div className="clearfix" />
                                    <table className="table table-bordered" style={{ marginTop: 15 }}>
                                        <thead style={{ background: '#d6d6d7' }}>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Title</th>
                                                <th width={100}>Image</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productList.map((product, index) => (
                                                <tr key={product._id}>
                                                    <th scope="row">{index}</th>
                                                    <td>{product.name}</td>
                                                    <td>
                                                        {product.image ? (
                                                            <img src={`${baseUrl}/filestorage/${product.image}`} alt="drgdf" className="img-responsive" />
                                                        ) : (
                                                            <img src="../images/no_img.jpg" alt="drgdf" className="img-responsive" />
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Link to={`/product/edit/${product._id}`} className="btn btn-primary btn-xs">
                                                            <i className="glyphicon glyphicon-edit" />
                                                        </Link>
                                                        <button onClick={(e) => DeleteProduct(product._id)} className="btn btn-primary btn-xs float-right">
                                                            <i className="glyphicon glyphicon-trash" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Product