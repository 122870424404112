import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';

const AddProduct = () => {
    const baseUrl = "https://modularkitchens.co.nz";
    const [parent_id, setParentId] = useState("");
    const [name, setName] = useState("");
    const [obj_scale, setObjScale] = useState("");
    const [obj_size, setObjSize] = useState("");
    const [image, setImage] = useState([]);
    const [obj_img, setObjImage] = useState([]);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        setImage(e.target.files[0]);
    }
    const handleChangeObj = (e) => {
        setObjImage(e.target.files[0]);
    }

    const handleAddProduct = async (e) => {
        e.preventDefault();
        const tokenVal = localStorage.getItem('token');
        const token = tokenVal.replace(/^"|"$/g, '').trim();
        if (!token) {
            setError('You need to be logged in to change the password.');
            return;
        }

        const formData = new FormData();
        formData.append('parent_id', parent_id);
        formData.append('name', name);
        formData.append('image', image);
        formData.append('obj_img', obj_img);
        formData.append('obj_scale', obj_scale);
        formData.append('obj_size', obj_size);

        try {
            const response = await fetch(`${baseUrl}/products`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData,
            });

            const responseData = await response.json();

            if (responseData.success) {
                alert("Product saved");
                setError(null); // Clear any previous errors
                // Reset form fields and formData
                setParentId("");
                setName("");
                setObjScale("");
                setObjSize("");
                setImage([]);
            } else {
                setError("Product not saved. Please check your input data.");
            }
        } catch (error) {
            console.error(error);
            setError(error);
        }
    };
    // Product listing
    const [productList, setProductList] = useState([]);

    const getProducts = async () => {
        try {
            const response = await fetch(`${baseUrl}/products`, {
                method: 'GET',
            });
            const data = await response.json();
            setProductList(data.productList);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getProducts();
    }, []);
    console.log(productList)

    return (
        <div className="container-fluid">
            <Sidebar />
            <div className="right-section">
                <div className="dashboard-bar">
                    <h2 className="headn-h2">Products</h2>
                </div>
                <div className="main-section">
                    <div className="col-sm-12 col-md-6 col-lg-offset-3 col-xs-12">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h3 className="panel-title">Add Product</h3>
                            </div>
                            <div className="panel-body" style={{ padding: 30 }}>
                                {error && <div className="alert alert-danger">{error.message}</div>}
                                <form onSubmit={handleAddProduct} name="addpro" encType="multipart/form-data">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Parent</label>
                                        <select className="form-control" name="parent_id" value={parent_id} onChange={(e) => setParentId(e.target.value)}>
                                            <option value="">Parent</option>
                                            {productList.map((product) => (
                                                <option value={product._id} key={product._id}>{product.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Name</label>
                                        <input type="text" className="form-control" name="name" value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Image</label>
                                        <input className="form-control" type="file" name="image" onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Object</label>
                                        <input className="form-control" type="file" name="obj_img" onChange={handleChangeObj} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Object Scale</label>
                                        <input type="text" className="form-control" name="obj_scale" value={obj_scale} onChange={(e) => setObjScale(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Object Size</label>
                                        <input type="text" className="form-control" name="obj_size" value={obj_size} onChange={(e) => setObjSize(e.target.value)} />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddProduct;
