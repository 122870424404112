import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';

const EditProduct = () => {
    const { id } = useParams();
    const baseUrl = "https://modularkitchens.co.nz";
    const [parent_id, setParentId] = useState("");
    const [name, setName] = useState("");
    const [obj_scale, setObjScale] = useState("");
    const [obj_size, setObjSize] = useState("");
    const [image, setImage] = useState(null);
    const [obj_img, setObjImage] = useState(null);
    const [error, setError] = useState(null);
    const [productList, setProductList] = useState([]);
    const [selectedParent, setSelectedParent] = useState(""); // New state for selected parent
    const [productImage, setProductImage] = useState(""); // New state for product image
    const [productObj, setProductObj] = useState(""); // New state for product image

    const handleChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleChangeObj = (e) => {
        setObjImage(e.target.files[0]);
    };

    const handleEditProduct = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('parent_id', parent_id);
        formData.append('name', name);
        formData.append('obj_scale', obj_scale);
        formData.append('obj_size', obj_size);

        if (image) {
            formData.append('image', image);
        }

        if (obj_img) {
            formData.append('obj_img', obj_img);
        }

        try {
            const response = await fetch(`${baseUrl}/products/${id}`, {
                method: "PUT",
                body: formData,
            });

            const responseData = await response.json();

            if (responseData.success) {
                alert("Product updated");
                setError(null);
                setParentId("");
                setName("");
                setObjScale("");
                setObjSize("");
                setImage(null);
                setObjImage(null);
            } else {
                setError("Product not updated. Please check your input data.");
            }
        } catch (error) {
            console.error(error);
            setError(error);
        }
    };

    const getProductDetails = async () => {
        try {
            const response = await fetch(`${baseUrl}/products/${id}`, {
                method: 'GET',
            });
            const data = await response.json();

            setParentId(data.product.parent_id);
            setSelectedParent(data.product.parent_id); // Set selected parent
            setName(data.product.name);
            setObjScale(data.product.obj_scale);
            setObjSize(data.product.obj_size);
            setProductImage(data.product.image); // Set product image
            setProductObj(data.product.obj_img); // Set product obj
        } catch (error) {
            console.error(error);
        }
    };

    const fetchProductList = async () => {
        try {
            const response = await fetch(`${baseUrl}/products`, {
                method: 'GET',
            });
            const data = await response.json();
            setProductList(data.productList);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getProductDetails();
        fetchProductList();
    }, [id]);

    return (
        <div className="container-fluid">
            <Sidebar />
            <div className="right-section">
                <div className="dashboard-bar">
                    <h2 className="headn-h2">Products</h2>
                </div>
                <div className="main-section">
                    <div className="col-sm-12 col-md-6 col-lg-offset-3 col-xs-12">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h3 className="panel-title">Edit Product</h3>
                            </div>
                            <div className="panel-body" style={{ padding: 30 }}>
                                {error && <div className="alert alert-danger">{error.message}</div>}
                                <form onSubmit={handleEditProduct} name="editpro" encType="multipart/form-data">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Parent</label>
                                        <select
                                            className="form-control"
                                            name="parent_id"
                                            value={parent_id}
                                            onChange={(e) => setParentId(e.target.value)}
                                        >
                                            <option value="">Parent</option>
                                            {productList.map((product) => (
                                                <option value={product._id} key={product._id}>
                                                    {product.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Name</label>
                                        <input type="text" className="form-control" name="name" value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Image</label>
                                        {productImage && (
                                            <img
                                                src={`${baseUrl}/filestorage/${productImage}`}
                                                alt="Product"
                                                style={{ height: '150px', maxWidth: '100%' }}
                                            />
                                        )}
                                        <input className="form-control" type="file" name="image" onChange={handleChange} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Object</label>
                                        {productObj && (
                                            <Link to={`${baseUrl}/filestorage/${productObj}`}>Download</Link>
                                        )}
                                        <input className="form-control" type="file" name="obj_img" onChange={handleChangeObj} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Object Scale</label>
                                        <input type="text" className="form-control" name="obj_scale" value={obj_scale} onChange={(e) => setObjScale(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Object Size</label>
                                        <input type="text" className="form-control" name="obj_size" value={obj_size} onChange={(e) => setObjSize(e.target.value)} />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProduct;
