import React from 'react'
import Sidebar from './Sidebar'

const Home = () => {
    return (
        <>
            <div className="container-fluid">
                <Sidebar />
                <div className="right-section">
                    <div className="dashboard-bar">
                        <h2 className="headn-h2">Dashboard</h2>
                    </div>
                    <div className="main-section">
                        <div className="col-sm-12 col-lg-12 col-md-12 col-xs-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h3 className="panel-title">Welcome to Kitchen and Bath Rano</h3>
                                </div>
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home


