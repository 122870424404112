import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Sidebar = () => {
    let navigate = useNavigate();
    const siteUrl = "https://modularkitchens.nz";

    useEffect(() => {
        if (!localStorage.getItem('user')) {
            navigate('/login/');
        }
    })

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/login/");
    };
    return (
        <>
            <div className="left-section">
                <Link to="/"><img src={`${siteUrl}/images/logo.webp`} alt="img" className="img-responsive" /></Link>
                <h3 className="headn-h3">Admin Panel</h3>
                <div className="bs-example" data-example-id="simple-nav-stacked">
                    <ul className="nav nav-pills nav-stacked nav-pills-stacked-example">
                        <li role="presentation" className="active"><Link to="/">Dashboard</Link></li>
                        <li role="presentation"><Link to="#">Users</Link></li>
                        <li role="presentation"><Link to="/product/">Products</Link></li>
                        <li role="presentation"><Link to="#" onClick={handleLogout}>Logout</Link></li>
                    </ul>
                </div>
                <div className="develop">
                    <div className="row">
                        <div className="col-sm-6">
                            <p>Developed By: </p>
                        </div>
                        <div className="col-sm-6"> <Link to="#"><img src={`${siteUrl}/images/logo.webp`} alt="img" className="img-responsive" /></Link> </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar