import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    const baseUrl = "https://modularkitchens.co.nz";
    const [errMsg, setErrMsg] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${baseUrl}/users/signin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password })
            });

            const result = await response.json();

            if (result.success) {
                localStorage.setItem("token", JSON.stringify(result.user.token));
                localStorage.setItem("user", JSON.stringify(result.user));
                navigate('/');
            } else {
                setErrMsg(result.message);
            }
        } catch (error) {
            console.error('Login error:', error);
            setErrMsg('An error occurred during login.');
        }
    };

    return (
        <>
            <section id="bg">
                <div className="container">
                    <div className="login">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="logins" style={{ textAlign: 'center', padding: 10 }}>
                                    {errMsg && <div className="alert alert-danger">{errMsg}</div>}
                                    <form onSubmit={handleSubmit}>
                                        <div className="logins" style={{ textAlign: 'center' }}>
                                            <img src="../images/logo.webp" alt="Company Logo" />
                                        </div>
                                        <div className="form-group">
                                            <label className="font" htmlFor="user_name">Email:</label>
                                            <input type="email" name="email" placeholder="Email ID" className="form-controls font" required value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <label className="font" htmlFor="password">Password:</label>
                                            <input type="password" name="password" placeholder="Password" className="form-controls font" required value={password} onChange={(e) => setPassword(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" name="submit" className="ok text-dark style-design">Login</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Login;
